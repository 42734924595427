const dataWorkExperience = [
  {
    info: {
      id: "1",
      year: "2022 - 2023 | full-time",
      job: "IT Product Support",
      company: "PT. Inbitef Digital Learning",
      jobdesc:
        "Provided technical support to users facing issues with products, services, or applications, created documentation to educate users about product features and functionalities, conducted user training sessions or technical guidance, contributed to the redesign and development of the company website.",
    },
  },
  {
    info: {
      id: "2",
      year: "2020 - 2022 | self-employed",
      job: "Web & Social Media Designer",
      company: "Freelancer",
      jobdesc:
        "Designed user interfaces for websites and designed graphics for social media platforms.",
    },
  },
  {
    info: {
      id: "3",
      year: "2018 - 2019 | hybrid",
      job: "Web & Graphics Designer",
      company: "Wahdah Islamiyah Institution",
      jobdesc:
        "Designed graphics such as pamphlets, banners, and videos, and worked on web design to enhance the effectiveness of campaigns, especially for Cooperative Muslimah Wahdah Islamiyah.",
    },
  },
  {
    info: {
      id: "4",
      year: "2018 - 2019 | part-time",
      job: "IT Teacher",
      company: "Prima Tiara Makassar Vocational High School",
      jobdesc:
        "Delivered interactive lessons to students, covering topics such as the basics of web programming languages, network administration, and database management.",
    },
  },
  {
    info: {
      id: "5",
      year: "2017 | contract",
      job: "Administrator ",
      company: "Wahdah Islamiyah High School",
      jobdesc:
        " Managed general administrative tasks, oversaw student record, and handled such as scheduling, mail, organizing files and ensure the smooth operation of school facilities.",
    },
  },
];

export default dataWorkExperience;
