import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "./Sidebar";
import { toggleSidebar } from "../utils/sidebarSlice";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import DarkMode from "./DarkMode";

const Header = () => {
  const dispatch = useDispatch();
  const isDarkMode = useSelector((store) => store.mode.darkMode);
  const isOpenSidebar = useSelector((store) => store.sidebar.sidebarMenu);
  const handleToggleSidebar = () => {
    dispatch(toggleSidebar(isOpenSidebar));
  };

  return (
    <>
      <Sidebar />
      <div
        className={`fixed top-0 items-center xs:pl-[59%] sm:pl-[83%] md:pl-[59%] lg:pl-[69%] py-11 flex gap-10 font-semibold text-base shadow-md z-20 ${
          isDarkMode ? "bg-gray-900" : "bg-gray-50"
        }  w-full h-16`}
      >
        <div className="block xs:hidden sm:hidden md:block lg:block">
          <ul className="flex justify-center gap-10 cursor-pointer rounded-lg">
            <li
              className={` py-3 font-medium  ${
                isDarkMode
                  ? "text-gray-400 hover:text-white hover:font-bold"
                  : "text-gray-400 hover:text-pink-600 hover:font-bold"
              }`}
            >
              <Link to="/">Glimpse of me</Link>
            </li>
            <div
              className={`border rounded-full mt-4 mb-4 ${
                isDarkMode ? "border-gray-400" : "border-gray-300"
              }`}
            ></div>
            <li
              className={` py-3 font-medium  ${
                isDarkMode
                  ? "text-gray-400 hover:text-white hover:font-bold"
                  : "text-gray-400 hover:text-pink-600 hover:font-bold"
              }`}
            >
              <Link to="/projects">Featured works</Link>
            </li>
          </ul>
        </div>

        <div className="flex items-center">
          <DarkMode />
          <div className="hidden xs:block sm:block md:hidden lg:hidden">
            <div
              onClick={handleToggleSidebar}
              className="flex cursor-pointer items-center gap-2 py-2 px-4 rounded-lg "
            >
              {isOpenSidebar ? (
                <XMarkIcon className="text-gray-400 w-7 h-7 stroke-2" />
              ) : (
                <Bars3Icon className="text-gray-400 w-8 h-8 stroke-3 " />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
