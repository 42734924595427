import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Sidebar = () => {
  const isOpenSidebar = useSelector((store) => store.sidebar.sidebarMenu);
  const isDarkMode = useSelector((store) => store.mode.darkMode);

  if(!isOpenSidebar) return null;
  return (
    <>
      <aside
        className={`fixed hidden xs:block sm:block md:hidden lg:hidden overflow-hidden transition-all right-0 z-30 h-screen mt-20 bg-gray-50 text-gray-800 ${
          isOpenSidebar ? "w-[60%]" : "w-0"
        } ${isDarkMode ? "bg-gray-900" : "bg-gray-50"}`}
      >
        <nav className="my-5 text-center items-center">
          <h5 className="text-gray-500 text-xl pb-5 pt-2 font-bold text-right pr-10">
            Menu
          </h5>
          <ul className="cursor-pointer">
            <li
              className={`py-8 font-semibold text-right text-lg items-center pr-10 ${
                isDarkMode
                  ? "text-gray-50 hover:bg-pink-600"
                  : "text-gray-800 hover:bg-pink-600 hover:text-gray-50"
              }`}
            >
              <Link to="/">Glimpse of me</Link>
            </li>
            <li
              className={`py-8 font-semibold text-right text-lg items-center pr-10 ${
                isDarkMode
                  ? "text-gray-50 hover:bg-pink-600"
                  : "text-gray-800 hover:bg-pink-600 hover:text-gray-50"
              } `}
            >
              <Link to="/projects">Featured works</Link>
            </li>
          </ul>
        </nav>
      </aside>
    </>
  );
};

export default Sidebar;
